import React, {Component} from "react";
import styles from "./styles/Map.module.css";
import {GOOGLE_PROP_TYPES} from "../Types/Types";
import {MAP_STYLES} from "./styles/MapStyles";

interface MapProps extends GOOGLE_PROP_TYPES{
	lat: number,
	lng: number,
	setMapPosition?: object,
	startMapPosition?: object,
	onBoundsChanged: (...args: any[]) => any,
	drawerIsResizing: boolean,
	customDrawerHeight: number,
	isMobile: boolean
}
interface MapState {
	mapPosition: {
		center: { lat: number, lng: number },
		northEast: { lat: number, lng: number },
		southWest: { lat: number, lng: number }
	},
	mapZoom: any | number,
	mapInstance: any | null
}
class Map extends Component<MapProps | any, MapState | any> {
	mapDivRef = React.createRef<HTMLDivElement>();
	boundingBoxUpdateTimeout: number | null = null;
	fitBoundsPadding = {
		left: 350+20, // width + position left + extra space on right side
		bottom: 200,
		top: 30
	};
	constructor(props:any) {
		super(props);
		this.state = {
			mapInstance: null,
			mapPosition: {
				center: {
					lat: 0,
					lng: 0,
				},
				northEast: {
					lat: 0,
					lng: 0,
				},
				southWest: {
					lat: 0,
					lng: 0,
				}
			},
			mapZoom: 8
		};

	}

	updateMapStateData(mapInstance: any) {
		let bounds = mapInstance.getBounds();
		let center = bounds.getCenter();
		let ne = bounds.getNorthEast();
		let sw = bounds.getSouthWest();
		let zoom = mapInstance.getZoom();
		this.setState({
			mapPosition: {
				center: {
					lat: center.lat(),
					lng: center.lng(),
				},
				northEast: {
					lat: ne.lat(),
					lng: ne.lng(),
				},
				southWest: {
					lat: sw.lat(),
					lng: sw.lng(),
				}
			},
			mapZoom: zoom
		});
	}
	loadGoogleMaps() {


		let mapInstance = new this.props.google.maps.Map(this.mapDivRef.current, {
			center: {lat: this.props.lat, lng: this.props.lng},
			zoom: this.props.zoom, disableDefaultUI: true,
			minZoom: 5,
			mapTypeId: this.props.mapTypeId,
			styles: MAP_STYLES,
		});
		// When we have county or municipality selected from start, we will have setMapPosition
		if (this.props.setMapPosition) {
			let southWestLatLng = new this.props.google.maps.LatLng(
				this.props.setMapPosition.southWest.lat,
				this.props.setMapPosition.southWest.lng
			);
			let northEastLatLng = new this.props.google.maps.LatLng(
				this.props.setMapPosition.northEast.lat,
				this.props.setMapPosition.northEast.lng
			);
			let bounds = new this.props.google.maps.LatLngBounds(
				southWestLatLng,
				northEastLatLng
			);

			this.fitBounds(mapInstance, bounds);
		} else if (this.props.startMapPosition) {
			// Otherwise we will fitBounds to startPosition (boundingbox around concession areas)
			let southWestLatLng = new this.props.google.maps.LatLng(
				this.props.startMapPosition.southWest.lat,
				this.props.startMapPosition.southWest.lng
			);
			let northEastLatLng = new this.props.google.maps.LatLng(
				this.props.startMapPosition.northEast.lat,
				this.props.startMapPosition.northEast.lng
			);
			let bounds = new this.props.google.maps.LatLngBounds(
				southWestLatLng,
				northEastLatLng
			);
			this.fitBounds(mapInstance, bounds);
		}

		this.setState({
			mapInstance: mapInstance
		});

		this.listeners.push(
			mapInstance.addListener("bounds_changed", () => {
				this.updateMapStateData(mapInstance);
				// Filter recurring updates
				if (this.boundingBoxUpdateTimeout === null)
					this.boundingBoxUpdateTimeout = window.setTimeout(() => {
						this.boundingBoxUpdateTimeout = null;
						this.props.onBoundsChanged(
							this.state.mapPosition,
							this.state.mapZoom
						);
					}, 100);
			})
		);

	}
	fitBounds(mapInstance: any, bounds: Object) {
		if (this.props.isMobile)
			mapInstance.fitBounds(bounds);
		else
			mapInstance.fitBounds(bounds, this.fitBoundsPadding);
	}


	componentDidMount() {
		if (!this.props.google)
			return;

		this.loadGoogleMaps();
	}

	componentWillUnmount(): void {
		this.listeners.forEach((listener: any) => listener.remove());
		this.listeners = [];
	}

	listeners: any = [];
	componentDidUpdate(prevProps: any) {
		if (this.props.google !== prevProps.google) {
			this.loadGoogleMaps();
		}


		if (!this.props.google) {
			return;

		}
		/**
		 * Detect incoming position and zoom changes that are not caused by the user moving the map manually
		 *
		 * @type {boolean}
		 */
		let updateMapPositionFromProps = false;
		let updateMapZoomFromProps = false;
		let updateMapTypeFromProps = false;

		if (this.props.lat !== prevProps.lat) {
			if (this.props.lat !== this.state.mapPosition.center.lat) {
				updateMapPositionFromProps = true;
			}
		}
		if (this.props.lng !== prevProps.lng) {
			if (this.props.lng !== this.state.mapPosition.center.lng) {
				updateMapPositionFromProps = true;
			}
		}
		if (this.props.zoom !== prevProps.zoom) {
			if (this.props.zoom !== this.state.mapZoom) {

				updateMapZoomFromProps = true;
			}
		}
		if (this.props.mapTypeId !== prevProps.mapTypeId)
			updateMapTypeFromProps = true;

		if (updateMapPositionFromProps)
			this.state.mapInstance.setCenter(new this.props.google.maps.LatLng(this.props.lat, this.props.lng));

		if (updateMapZoomFromProps)
			this.state.mapInstance.setZoom(this.props.zoom);

		if (updateMapTypeFromProps)
			this.state.mapInstance.setMapTypeId(this.props.mapTypeId);


	}


	render() {
		const childrenWithProps = React.Children.map(this.props.children, child => {
                if (child) {
					// noinspection JSUnresolvedFunction
					return React.cloneElement(child as any, {google: this.props.google, map: this.state.mapInstance})
				}
            }
		);



		let style={};
		let resizingClass = '';
		if (this.props.customDrawerHeight > 0 && this.props.customDrawerHeight < window.innerHeight)
			style = {'bottom':this.props.customDrawerHeight+'px'};
		if (this.props.drawerIsResizing)
			resizingClass = styles.resizing;

		let className = styles.map;
		if (resizingClass)
			className += ' ' + resizingClass;

		return (
			<div className={styles.mapWrapper}>
				<div ref={this.mapDivRef} className={className} style={style}/>
				{ childrenWithProps }
			</div>
		);
	}
}

export default Map;
