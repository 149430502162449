import React, { Component } from 'react';
import icon from '../common/images/location-icon.svg';
import styles from './UserLocation.module.css';
import {GOOGLE_PROP_TYPES} from "../Types/Types";

interface UserLocationProps extends GOOGLE_PROP_TYPES{
	isMobile?: boolean,
	onLocationChanged: (...args: any[]) => any
}
export default class UserLocation extends Component<UserLocationProps, {}> {
	constructor(props: any) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}
	getClassNames(isMobile: boolean) {
		return {
			className: isMobile ? styles.mobile : styles.desktop
		}
	}
	render() {
		let {className} = this.getClassNames(true);
		return <img src={icon} alt="My location" className={className} onClick={this.onClick} onTouchEnd={this.onClick}/>
	}

	onClick() {
		if ('geolocation' in navigator) {
			navigator.geolocation.getCurrentPosition(
				position => {
					if (position && position.coords)
						this.props.onLocationChanged(position.coords.latitude, position.coords.longitude);
				},
				error => {
					alert('error! ' + error.message);
					console.log(error)
				},
				options
			)
		}
	}
}

let options = {
	enableHighAccuracy: true
};