import React, {Component} from 'react';
import './App.css';
import Map from './map/Map'
import CurrentLocation from "./map/CurrentLocation";
import {
    MapPositionType,
} from "./Types/Types";
import ZoomControl from "./map/ZoomControl";
import UserLocation from "./map/UserLocation";
import Menu from "./util/window";
import {isMobile} from "react-device-detect";
import MapType from "./map/MapType";
import Navigation from "./util/Navigation";

type State = {

    googleMapsApiLoaded: boolean,
    mapLat: number,
    mapLng: number,
    mapZoom: number,
    mapTypeId: string,
    mapPosition?: MapPositionType,
    setMapPosition?: MapPositionType,
    customDrawerHeight: number,
    drawerIsResizing: boolean,
    isMobile: boolean,
    menuactive: boolean,

}

class App extends Component<any, State> {

    defaultStartMapPosition = {
        mapLat: 59.4182992,
        mapLng: 14.1471929,
        mapZoom: 8,
        mapTypeId: 'roadmap',
        mapPosition: {
            // Boundingbox for Ellevio's koncession areas
            southWest: {
                lat: 56.5070,
                lng: 11.1909
            },
            northEast: {
                lat: 62.5098,
                lng: 19.2806
            }
        }
    };
    constructor(props: any) {
        super(props);
        this.changeMapType = this.changeMapType.bind(this);
        this.state = {
            googleMapsApiLoaded: false,
            ...this.defaultStartMapPosition,
            setMapPosition: undefined,
            customDrawerHeight: 0,
            drawerIsResizing: false,
            isMobile: false,
            menuactive: false
        };
    }

    onLoad() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    if (position && position.coords)
                        this.centerMap(position.coords.latitude, position.coords.longitude);
                },
                error => {
                    alert('error! ' + error.message);
                    console.log(error)
                }
            )
        }
    }

    componentDidMount() {
        window.initGoogleMapsApi = () => {
            this.setState({
                googleMapsApiLoaded: true
            });
        };
        if (isMobile)
            this.setState({isMobile: true})

        this.loadGoogleMapsApi();
        this.onLoad()
    }

    onZoomIn() {
        this.setState({mapZoom: this.state.mapZoom + 1});
    }

    onZoomOut() {
        this.setState({mapZoom: this.state.mapZoom - 1});
    }
    loadGoogleMapsApi() {
        const script = document.createElement("script");
        script.src = "https://maps.googleapis.com/maps/api/js?key=" + process.env.REACT_APP_GOOGLE_API_KEY + "&language=sv&region=SE&libraries=places&callback=initGoogleMapsApi";
        script.async = true;
        script.defer = true;

        document.body.appendChild(script);
    }

    onBoundingBoxChanged(mapPosition: MapPositionType, mapZoom: number) {
        if (mapPosition !== null) {
            if (mapPosition.center && mapPosition.center.lat !== null && mapPosition.center.lng !== null) {
                this.setState({
                    mapLat: mapPosition.center.lat,
                    mapLng: mapPosition.center.lng,
                    mapZoom,
                    mapPosition
                });
            }
        }
    }

    centerMap(lat: number, lng: number) {
        this.setState({mapLat: lat, mapLng: lng, mapZoom: 14});
    }
    changeMapType(type: string) {
        this.setState({mapTypeId: type})
    }
    render() {
        let googleAPI = null;
        if (this.state.googleMapsApiLoaded)
            googleAPI = window.google;

        return (
            <div className="App">
                <Map google={googleAPI}
                     customDrawerHeight={this.state.isMobile ? this.state.customDrawerHeight : 0}
                     drawerIsResizing={this.state.drawerIsResizing}
                     isMobile={this.state.isMobile}
                     lat={this.state.mapLat}
                     lng={this.state.mapLng}
                     mapTypeId={this.state.mapTypeId}
                     startMapPosition={this.state.mapPosition}
                     onBoundsChanged={(mapPosition: MapPositionType, mapZoom: number) => this.onBoundingBoxChanged(mapPosition, mapZoom)}
                     zoom={this.state.mapZoom}
                >
                        <div id='cross'/>
                        <Menu/>
                        <Navigation>
                            <MapType changeMapType={this.changeMapType}/>
                            <UserLocation onLocationChanged={(lat, lng) => this.centerMap(lat, lng)}/>
                            <ZoomControl onZoomIn={() => this.onZoomIn()} onZoomOut={() => this.onZoomOut()}/>
                        </Navigation>
                        <CurrentLocation/>
                </Map>
            </div>
        );
    }
}

export default App;
