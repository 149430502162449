import React, { Component } from "react"
import {GOOGLE_PROP_TYPES} from "../Types/Types";
import styles from "./window.module.css";
import logo from "../common/images/ellevio-logo-neg.svg";


interface WindowState {
	menuactive: boolean
}

export default class Window extends Component<GOOGLE_PROP_TYPES, WindowState> {

	constructor(props: any) {
		super(props);
		this.state = {
			menuactive: true,

		}
	}
	toggleMenu() {
		if(!this.state.menuactive) {
			this.setState({menuactive: true});
		} else {
			this.setState({menuactive: false});
		}

	}

	getClassNames(isMobile: boolean) {
		return {
			className: isMobile ? styles.mobile : styles.desktop,
			topcontainer: isMobile ? styles.mobiletopcontainer : styles.topcontainer,
			middlecontainer: styles.middlecontainer,
			infoClass: isMobile ? styles.infoContainer : styles.hide,
			imagelogo: isMobile ? styles.mobileimagelogo : styles.imagelogo,
		}
	}

	render() {
		let {
			className,
			topcontainer,
			middlecontainer,
			infoClass,
			imagelogo,
		} = this.getClassNames(true);


		if(!this.state.menuactive) {
			middlecontainer += ' ' + styles.closed;
		}

		const childrenWithProps = React.Children.map(this.props.children, child => {
				if (child)
				{ // noinspection JSUnresolvedFunction
					return React.cloneElement(child as any, {google: this.props.google, map: this.props.map})
				}
			}
		);

		return (
			<div className={className}>
				<div className={topcontainer}>
					<img className={imagelogo} src={logo} alt="Ellevio logotyp"/>
					<div className={infoClass} onClick={() => this.toggleMenu()}>?</div>
				</div>
				<div className={middlecontainer}>
					<p className={styles.text}>Välkommen till Min Plats. Den här tjänsten används av Ellevios kundtjänst för att bestämma kartposition vid inkommande samtal.</p>
					<p className={styles.text}>Leta upp din plats i kartan och klicka på "Visa platskod", läs upp koden för kundtjänst och med hjälp av den kan de hitta vart du befinner dig.</p>
					<p className={styles.text}>Enklast är att använda "siktet" för att använda din enhets GPS-position, men du kan också panorera och zooma i kartan som vanligt.</p>

					<p style={{textAlign: 'center'}}>
						<button className={styles.button} onClick={() => this.toggleMenu()}>{'Ok'}</button>
					</p>
				</div>
				{this.state.menuactive?null:childrenWithProps}
			</div>
		);
	}
}