export const MAP_STYLES_DARK = [
	{
		"elementType": "geometry.fill",
		"stylers": [
			{
				"color": "#36393E"
			}
		]
	},
	{
		"featureType": "landscape",
		"elementType": "geometry.fill",
		"stylers": [
			{
				"color": "#36393E"
			}
		]
	},
	{
		"featureType": "all",
		"elementType": "labels.text.stroke",
		"stylers": [
			{
				"visibility": "on"
			},
			{
				"color": "#000000"
			},
			{
				"lightness": 16
			}
		]
	},
	{
		"featureType": "all",
		"elementType": "labels.text.fill",
		"stylers": [
			{
				"saturation": 36
			},
			{
				"color": "#ffffff"
			},
			{
				"lightness": 40
			}
		]
	},
	{
		"featureType": "poi",
		"elementType": "labels",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "road",
		"elementType": "geometry.fill",
		"stylers": [
			{
				"color": "#4f5359"
			}
		]
	},
	{
		"featureType": "road.highway",
		"elementType": "geometry.fill",
		"stylers": [
			{
				"color": "#ff8200"
			},
			{
				"lightness": 17
			}
		]
	},
	{
		"featureType": "road.highway",
		"elementType": "geometry.stroke",
		"stylers": [
			{
				"color": "#ff8200"
			},
			{
				"lightness": 29
			},
			{
				"weight": 0.2
			}
		]
	},

	/*{
		"featureType": "road",
		"elementType": "geometry.stroke",
		"stylers": [
			{
				"color": "#ff8200"
			}
		]
	},*/
	{
		"featureType": "road",
		"elementType": "labels",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "transit",
		"elementType": "geometry",
		"stylers": [
			{
				"color": "#ff8200"
			},
			{
				"lightness": 19
			}
		]
	},
	{
		"featureType": "water",
		"stylers": [
			{
				"color": "#03132b"
			}
		]
	}
];

export const MAP_STYLES = [
	{
		"elementType": "geometry.fill",
		"stylers": [
			{
				"color": "#e6e9eb"
			}
		]
	},
	{
		"featureType": "landscape",
		"elementType": "geometry.fill",
		"stylers": [
			{
				"color": "#e6e9eb"
			}
		]
	},
	{
		"featureType": "poi",
		"elementType": "labels",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "road",
		"elementType": "geometry.fill",
		"stylers": [
			{
				"color": "#f1f4f6"
			}
		]
	},
	{
		"featureType": "road",
		"elementType": "geometry.stroke",
		"stylers": [
			{
				"color": "#f1f4f6"
			}
		]
	},
	{
		"featureType": "road",
		"elementType": "labels",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "water",
		"stylers": [
			{
				"color": "#97b6e0"
			}
		]
	}
];




