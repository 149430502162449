import React, { Component } from "react";
import styles from "./ZoomControl.module.css";

import zoomIn from "../common/images/zoom-in.svg";
import zoomOut from "../common/images/zoom-out.svg";

interface ZoomControlProps{
	isMobile?: boolean,
	onZoomIn: (...args: any[]) => any,
	onZoomOut: (...args: any[]) => any
}
export default class ZoomControl extends Component<ZoomControlProps, any> {
	getClassNames(isMobile: boolean) {
		return {
			className: isMobile ? styles.mobile : styles.desktop
		}
	}
	render() {
		let {className}= this.getClassNames(true);
		return(
		<div className={className}>
			<img className={styles.button} src={zoomIn} alt="" onClick={this.props.onZoomIn}/>
			<div className={styles.spacer}/>
			<img className={styles.button} src={zoomOut} alt="" onClick={this.props.onZoomOut}/>
		</div>
		)}
}
