import React, { Component } from 'react';
import styles from "./MapType.module.css";
import satellite from "../common/images/satellite.svg";
import roadMap from "../common/images/mapRoads.svg";
import {GOOGLE_PROP_TYPES} from "../Types/Types";

interface MapTypeProps extends GOOGLE_PROP_TYPES{
    isMobile?: boolean,
    changeMapType: Function
}

export default class MapType extends Component<MapTypeProps,any>{
    constructor(props: any) {
        super(props);
        this.onTypeClick = this.onTypeClick.bind(this);
    }
    getClassNames(isMobile: boolean) {
        return {
            className: isMobile ? styles.mobile : styles.desktop
        }
    }
    render() {
        let {className} = this.getClassNames(true);
        return (
            <div className={className}>
                <img src={satellite} alt="satellite" className={styles.button} onClick={() => this.onTypeClick('satellite')} onTouchEnd={() => this.onTypeClick('satellite')}/>
                <div className={styles.space}/>
                <img src={roadMap} alt="roadmap" className={styles.button} onClick={() => this.onTypeClick('roadmap')} onTouchEnd={() => this.onTypeClick('roadmap')}/>
            </div>
            )
    }
    onTypeClick(type: string) {
        this.props.changeMapType(type)
    }

}