import React, { Component } from "react";
import {GOOGLE_PROP_TYPES} from "../Types/Types";
import style from './Navigation.module.css'

/**
 * Utility class used to compose the navigation.
 * Uses a MediaQuery-package to render the view according to appropriate browser resolution and size
 */
export default class Navigation extends Component<GOOGLE_PROP_TYPES, {}> {

    getClassNames(isMobile: boolean) {
        return {
            nav: isMobile ? style.navMobile : style.nav
        }
    }
    render() {
        let {nav} = this.getClassNames(true);
        const childrenWithProps = React.Children.map(this.props.children, child => {
                if (child)
                { // noinspection JSUnresolvedFunction
                    return React.cloneElement(child as any, {google: this.props.google, map: this.props.map})
                }
            }
        );

        return (
            <div className={nav}>
                {childrenWithProps}
            </div>
        )
    }

}