import React, { Component } from 'react';
import styles from './CurrentLocation.module.css';
import {GOOGLE_PROP_TYPES} from '../Types/Types';
// Bounding box
let bbBottomLeftLat = 55.128649;
let bbBottomLeftLng = 11.337891;
let bbTopRightLat = 68.911005;
let bbTopRightLng = 23.466797;

// BoundingBox Sverige WGS84 (lat, long, nedre vänstre hörnet, över högre hörnet)
let swedenHeight = bbTopRightLat - bbBottomLeftLat;
let swedenWidth = bbTopRightLng - bbBottomLeftLng;

// Number of slots we want to divide all the coordinates on
let slots = parseInt("zzz", 36);
let latSlots = slots / swedenHeight;
let lngSlots = slots / swedenWidth;
let encodedLatLng = "";

interface CurrentLocationProps extends GOOGLE_PROP_TYPES{

	isMobile: boolean
}
interface CurrentLocationState {
	displayedCode: any | null
}
export default class CurrentLocation extends Component<CurrentLocationProps | any, CurrentLocationState | any> {

	constructor(props:any) {
		super(props);
		this.state = {
			displayedCode: "",
			overlay: styles.hide,
			containerOverlay: styles.container,
			buttonText: "Visa platskod"
		};
		this.displayCode = this.displayCode.bind(this);
		this.exit = this.exit.bind(this)
	}

	getClassNames(isMobile: boolean) {
		return {
			desktopContainer: isMobile ? styles.hide : styles.container,
			code: isMobile ? styles.mobilecode : styles.code,
			mobileBtn: isMobile ? styles.button : styles.hide,
		}

	}

	render() {
		const {
			desktopContainer,
			code,
			mobileBtn,
		} = this.getClassNames(true);

		if (this.props.map) {
			encodedLatLng = this.encode(this.props.map.center.lat(), this.props.map.center.lng());
			this.decode(encodedLatLng);
		} //else console.log('no map');
		return (
			<div className={styles.container}>
				<div className={this.state.overlay}>
					<div className={styles.codeContainer}>
						<p className={styles.text}>Din platskod är:</p>
						<p className={code}>{this.state.displayedCode}</p>
					</div>
				</div>
				<div className={desktopContainer}>
					<button className={styles.button} onClick={this.displayCode}>{this.state.buttonText}</button>
					<span className={code}>{this.state.displayedCode}</span>
				</div>
				<button className={mobileBtn} onClick={this.displayCode}>{this.state.buttonText}</button>
			</div>

		);
	}

	displayCode() {
		if (this.state.overlay !== styles.overlay)
			this.setState({displayedCode: encodedLatLng, overlay: styles.overlay, containerOverlay: styles.containerOverlay, buttonText: "Stäng"});
		else
			this.exit()
	}
	exit(){
		this.setState({displayedCode: encodedLatLng, overlay: styles.hide, containerOverlay: styles.container, buttonText: "Visa platskod"})
	}
	// noinspection JSMethodCanBeStatic
	decode(encodedLatLng: any) {

		let lat, lng;

		if (encodedLatLng.length === 7) {
			let encodedLat = encodedLatLng.substring(0, 3);
			let encodedLng = encodedLatLng.substring(3, 6);
			let encodedChecksum = encodedLatLng.substring(6,7);

			let latSlot = parseInt(encodedLat, 36);
			let lngSlot = parseInt(encodedLng, 36);
			let checksum = parseInt(encodedChecksum, 36);

			let verifyChecksum = ((latSlot+lngSlot) % 36);
			if (verifyChecksum === checksum) {
				lat = (latSlot / latSlots) + bbBottomLeftLat;
				lng = (lngSlot / lngSlots) + bbBottomLeftLng;
			}
			//else console.error('Checksum does not match');
		}

		return {
			lat, lng
		}
	}

	// noinspection JSMethodCanBeStatic
	encode(lat: number, lng: number) {
		// Get a lat,lng relative to where it in located in the Sweden box, value between 0 and bbTopRightLat/bbTopRightLng
		let latInBox = lat - bbBottomLeftLat;
		let lngInBox = lng - bbBottomLeftLng;

		let slotLatInBox = Math.round(latInBox*latSlots);
		let slotLngInBox = Math.round(lngInBox*lngSlots);

		let encodedLat = '';
		let encodedLng = '';
		let checksum = '0';

		if (slotLatInBox < slots && slotLatInBox > 0 && slotLngInBox < slots && slotLngInBox > 0) {
			encodedLat = Math.round(slotLatInBox).toString(36);
			encodedLng =  Math.round(slotLngInBox).toString(36);

			checksum = ((slotLatInBox+slotLngInBox) % 36).toString(36);
		}
		return encodedLat.padStart(3, '0')+encodedLng.padStart(3, '0') + checksum;
	}

}

